<template>
    <div class="upload-image">
        <!-- 表单 -->
        <el-form :model="uploadErrandForm" ref="uploadErrandForm" :rules="uploadErrandFormRules">
            <el-form-item label="任务名称" :label-width="formLabelWidth" prop="errandName">
                <el-input v-model="uploadErrandForm.errandName" auto-complete="off" :disabled="!isCreateOrExport"></el-input>
            </el-form-item>
            <el-form-item label="线路名称" :label-width="formLabelWidth" prop="lineId">
                <el-select v-model="uploadErrandForm.lineId" placeholder="请选择" :disabled="!isCreateOrExport">
                    <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="杆塔" :label-width="formLabelWidth" prop="towerId">
                <el-select v-model="uploadErrandForm.towerId" placeholder="请选择杆塔">
                    <el-option v-for="(item, index) in towerList" :key="index" :label="item.towerName" :value="item.towerId"></el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <!-- 上传文件 -->
        <uploader
            ref="uploader"
            browse_button="browse_button"
            :url="server_config+'/eprdms/v2/loadFile/upload/photo'"
            :max_retries="3"
            :headers="{Authorization:Authorization}"
            :filters="{prevent_duplicates:true}"
            :Error="uploadError"
            :UploadComplete="uploadSuccess"
            :BeforeUpload="beforeUpload"
            :FilesAdded="filesAdded"
            @inputUploader="inputUploader"
            file_data_name="picture"
        />

        <!-- 待上传的文件表格 -->
        <el-table
        ref="fileTable"
        :data="fileTable"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="250"
        >
            <el-table-column
                label="文件名"
                show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="大小"
                width="120">
                <template slot-scope="scope">
                    <span>{{scope.row.size | sizeFilter}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="状态"
                width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 1">未上传</span>
                    <span v-if="scope.row.status === 4" style="color: brown">上传失败</span>
                    <span v-if="scope.row.status === 5" style="color: fileupreuse">已上传</span>
                </template>
            </el-table-column>
            <el-table-column
            label="操作"
            width="120">
            <template slot-scope="scope">
                <el-button
                size="mini"
                type="danger"
                class="kr-normal-button"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>                
            </template>
            </el-table-column>
        </el-table>            

        <div class="btnGroup">
            <el-button class="kr-normal-button" :disabled="uploading" type="primary" id="browse_button">选择文件</el-button>
            <el-button class="kr-normal-button" :disabled="uploading" @click="uploadFile">开始上传</el-button>
            <div class="el-upload__tip" slot="tip">仅支持jpg,png,jpeg,bmp格式图
            </div>            
        </div>
    </div>
</template>

<script>
import { getTowerNameList } from "@/http/towerListDetailsApi.js";
import config from "@/http/config.js";
import Uploader from '../common/Uploader.vue';
import moment from "moment";
export default {
    components: {
        'uploader':Uploader
    },

    props: {
        /*
         * @Date 2022/06/13 09:12:30
         * 当前表格行信息
         */
        row: {
            type: Object,
            default: () => {
                return {};
            }
        },

        /*
         * @Date 2022/06/13 09:21:36
         * 可选线路列表
         */
        lineList: {
            type: Array,
            default: () => {
                return [];
            }
        },

        /*
         * @Date 2022/06/13 09:22:24
         * 是否新增任务 / 导出任务，不是的话就禁止表单修改
         */
        isCreateOrExport: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },

    data() {
        return {
            /*
             * @Date 2022/06/10 17:42:24
             * 表单信息
             */
            uploadErrandForm: {
                errandName: "",
                lineId: "",
                towerId: ""
            },
            uploadErrandFormRules: {
                errandName: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
                lineId: [{ required: true, message: "请选择线路", trigger: "blur" }],
                towerId: [{ required: true, message: "请选择杆塔", trigger: "blur" }]
            },
            formLabelWidth: "5vw",
            towerList: [],           // 可选杆塔


            /*
             * @Date 2022/06/13 09:25:26
             * 表格信息
             */
            Authorization: sessionStorage.getItem("Authorization"),
            fileTable: [],                         // 文件表格信息
            up: {},                                // uploader对象
            server_config: config.baseURL,         // 上传图片的接口路径
            files: [],                             // input:file选择的文件
            uploading: false,                      // 是否正在上传
        }
    },

    methods: {
        /**
         * @Author KR0288
         * @Date 2022/06/13 09:16:08
         * @Description 获取塔杆列表
         */
        getTowerData(lineId) {
            getTowerNameList({ lineId: lineId }).then(res => {
                if (res.code === "000000") {
                    this.towerList = res.data;
                } else {
                    this.towerList = [];
                }
            });
        },

        /**
         * @Author KR0288
         * @Date 2022/06/13 09:35:42
         * @Description 删除表格中文件，同步到待上传文件
         */
        handleDelete(index, row) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fileTable.splice(index, 1);
                this.up.removeFile(this.up.getFile(row.id))
                console.log(this.up.files);
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },

        /**
         * @Author KR0288
         * @Date 2022/06/13 13:38:39
         * @Description 初始化uploader
         */
        inputUploader(up) {
            this.up = up;
            this.files = up.files
        },

        /**
         * @Author KR0288
         * @Date 2022/06/14 11:21:51
         * @Description 将新选择的待上传文件添加到表格中
         */
        filesAdded(up, currentFile) {},

        /**
         * @Author KR0288
         * @Date 2022/06/14 14:21:04
         * @Description 上传前设置参数
         */
        beforeUpload(up, file) {
            up.setOption("multipart_params", {
                "errandId": this.uploadErrandForm.errandId, 
                "towerId": this.uploadErrandForm.towerId
            })
            return true;
        },

        /**
         * @Author KR0288
         * @Date 2022/06/13 09:36:37
         * @Description 上传文件
         */
        uploadFile() {    
            this.$refs.uploadErrandForm.validate(valid => {
                if (valid) {
                    this.$confirm('此操作将上传该图片, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if(this.up.files.length == 0) {
                            this.$message({
                                type: "error",
                                message: "请先选择文件"
                            })
                        }else {
                            try {
                                this.up.start();
                                this.uploading = true;
                            } catch(e) {
                                this.uploading = false;
                                this.$message({
                                    type: "error",
                                    message: e
                                })
                            }
                        }
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "取消上传"
                        })
                    });
                }else {
                    this.$message({
                        type: "error",
                        message: "请先完成表单信息"
                    })
                }
            });
        },

        /**
         * @Author KR0288
         * @Date 2022/06/10 15:45:59
         * @Description 图片上传失败回调函数
         */
        uploadError(up, err) {
            this.$message.error(err.message);
            this.uploading = false;
        },

        /**
         * @Author KR0288
         * @Date 2022/06/10 15:45:34
         * @Description 图片上传成功回调函数
         */
        uploadSuccess(uploader, files) {
            if(uploader.total.failed !== 0) {
                this.$message({
                    type: "error",
                    message: "上传失败"
                })
            }else {
                this.$message({
                    type: "success",
                    message: "上传成功"  
                })
            }
            this.uploading = false;
        },
    },

    watch: {
        /*
         * @Date 2022/06/13 09:14:16
         * 根据表格行更新表单信息
         */
        row: function(newValue, oldValue) {
            this.uploadErrandForm.errandName = newValue.errandName;
            this.uploadErrandForm.errandId = newValue.errandId;
            this.uploadErrandForm.lineId = newValue.lineId;
        },

        /*
         * @Date 2022/06/14 17:00:56
         * 所选文件改变
         */
        files: {
            handler() {
                this.fileTable = [];
                this.files.forEach((e) => {
                    this.fileTable.push(e);
                });
                console.log(this.fileTable);
            },
            deep: true
        }
    },

    filters: {
        /*
         * @Date 2022/06/15 09:35:13
         * 计算图片大小
         */
        sizeFilter(value) {
            if(value) {
                if(Number(value) / 1024 < 1024) {
                    return parseFloat(Number(value / 1024)).toFixed(2) + 'KB'
                }else {
                    return parseFloat(Number(value / 1024 / 1024)).toFixed(2) + 'MB';
                }                
            }
        }
    },

    mounted() {
        this.uploadErrandForm.errandName = this.row.errandName;
        this.uploadErrandForm.errandId = this.row.errandId;
        this.uploadErrandForm.lineId = this.row.lineId;
        this.getTowerData(this.row.lineId);
    },
}
</script>

<style lang="scss" scoped>
  .btnGroup {
    margin-top: .1172rem
  }
</style>
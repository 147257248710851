import { render, staticRenderFns } from "./analysis.vue?vue&type=template&id=023859a7&scoped=true&"
import script from "./analysis.vue?vue&type=script&lang=js&"
export * from "./analysis.vue?vue&type=script&lang=js&"
import style0 from "./analysis.vue?vue&type=style&index=0&id=023859a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023859a7",
  null
  
)

export default component.exports
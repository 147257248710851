<template>
  <div class="analysis-page">
    <RouterMenu />
    <div class="main">
      <div class="top-button">
        <span>当前正在运行任务数：{{ total }}</span>
        <div class="search-input">
          <el-button class="kr-normal-button" style="padding:1vh;" @click="addFn" v-if="$hasElement('errand-form-add')">新增任务</el-button>
          <el-button class="kr-normal-button" style="padding:1vh;margin-right:1vw" v-if="$hasElement('errand-form-get')" @click="filterErrandDialogVisible = true">筛选任务</el-button>
        </div>
      </div>
      <el-table :data="tableData" border style="width: 100%" height="78vh">
       <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              <Icon name="bottomArrow" v-if="!scope.row.isRename" />
              <span>{{ (pageNumber - 1) * pageSize + scope["$index"] + 1 }}</span>
            </template>
          </el-table-column>
        <el-table-column prop="errandName" label="任务名称" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.isRename" class="taskname-color" @click="toDetails(scope.row)">{{ scope.row.errandName }}</span>
            <el-input v-else v-model="newName" class="rename-input" :ref="scope.row.id" @blur="rename(scope.row)" :autofocus="true"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="lineName" label="线路名" align="center"></el-table-column>
        <el-table-column prop="currentRun" label="已检测总数" align="center">
          <template slot-scope="scope">
            <span>{{ Number(scope.row.photoSum) - Number(scope.row.photoUncheck) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="photoSum" label="上传总数" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#00FABF" inactive-color="#00412f" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="检测进度" align="center">
          <template slot-scope="scope">
            <el-progress :percentage="scope.row.progress" :color="progressColor" style="color:#fff;"></el-progress>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button @click="toDetails(scope.row)" type="text" class="table-btn" title="查看详情"><i class="el-icon-view" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="uploadPhoto(scope.row)" type="text" class="table-btn" title="上传图片" v-if="$hasElement('errand-form-upload')"><i class="el-icon-upload2" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="downReport(scope.row)" type="text" class="table-btn" title="下载报告" v-if="$hasElement('errand-form-download')"><i class="el-icon-download" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="handleEdit(scope.$index, scope.row)" type="text" class="table-btn" title="编辑任务" v-if="$hasElement('errand-form-update')"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="handleDelete(scope.row)" type="text" class="table-btn" title="删除任务" v-if="$hasElement('errand-form-delete')"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-class">
        <!-- <el-pagination background layout="prev, pager, next" :total="total"></el-pagination> -->
        <el-pagination @current-change="filterErrand()" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>

    <!-- 创建任务和导出检测报告 -->
    <el-dialog class="my-dialog" :title="missionTitle" :close-on-click-modal="false" :visible.sync="errandFormDialogVisible" top="30vh" @closed="clearValidate('createErrandForm')">
      <el-form :model="errandForm" ref="createErrandForm" :rules="errandFormRules">
        <el-form-item label="线路名称" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model="errandForm.lineId" placeholder="请选择" :disabled="!isCreateOrExport">
            <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务名称" :label-width="formLabelWidth" prop="errandName">
          <el-input v-model="errandForm.errandName" auto-complete="off" :disabled="!isCreateOrExport"></el-input>
        </el-form-item>
        <el-form-item label="导出模式" :label-width="formLabelWidth" prop="exportMode" v-show="!isCreateOrExport">
          <el-radio-group v-model="errandForm.exportMode">
            <el-radio :label="1">全部图片</el-radio>
            <el-radio :label="2" checked>仅缺陷图片</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-if="isCreateOrExport">
        <el-button class="save-button" @click="createMission">创 建</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button class="cancel-button" @click="exportReport">导 出</el-button>
      </span>
    </el-dialog>

    <!-- 上传图片对话框 -->
    <el-dialog class="my-dialog" title="上传图片" :close-on-click-modal="false" :visible.sync="uploadPhotoDialogVisible" top="20vh" width="30%">
      <upload-image ref="uploadImage" :row="row" :lineList="lineList" :isCreateOrExport="isCreateOrExport" v-if="uploadPhotoDialogVisible"></upload-image>
    </el-dialog>
    
    <!-- 筛选任务对话框 -->
    <el-dialog class="my-dialog" title="筛选任务" :close-on-click-modal="false" :visible.sync="filterErrandDialogVisible" top="30vh">
      <el-form :model="filterForm" ref="filterFormRef">
        <el-form-item label="巡检线路" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model="filterForm.lineId" placeholder="请选择巡检线路">
            <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缺陷任务" :label-width="formLabelWidth" prop="errandName">
          <el-input v-model="filterForm.errandName" auto-complete="off" placeholder="请输入缺陷任务名称关键字"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="filterErrand(false)">取消筛选</el-button>
        <el-button class="save-button" @click="filterErrand(true)">应用筛选</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import RouterMenu from "@/components/RouterMenu.vue";
import Icon from "@/components/Icon.vue";
import { selectErrandWithPage, getupdateErrand, getaddErrand, getdeleteErrand, getExportReport } from "@/http/analysisApi.js";
import { getLineNames } from "@/http/homeApi.js";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import uploadImage from "../../components/analysis/uploadImage.vue"
export default {
  name: "analysis",
  components: {
    RouterMenu,
    Icon,
    uploadImage,
  },
  data() {
    return {
      progressColor: "#00ffbf",
      oldName: "", //旧的任务名
      newName: "", //新的任务名
      updateName: false,
      total: 0,
      pageNumber: 1,
      pageSize: 15,
      tableData: [],
      responseData: [],
      errandFormDialogVisible: false,
      uploadPhotoDialogVisible: false,
      filterErrandDialogVisible: false,
      isCreateOrExport: true, //创建任务还是导出
      missionTitle: "创建检测任务",
      formLabelWidth: "5vw",
      errandForm: {
        errandName: "",
        lineId: "",
        errandId: "",
        exportMode: 2
      },
      selectExportMode: [],
      errandFormRules: {
        errandName: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
        lineId: [{ required: true, message: "请选择线路", trigger: "blur" }]
      },
      filterForm: {
        lineId: "",
        errandName: ""
      },

      isFilter: false,          // 是否是处于筛选模式的表格

      /*
       * @Date 2022/06/13 09:11:11
       * 上传信息
       */
      row: null,     
      lineList: [],            // 可选线路   
    };
  },
  mounted() {
    this.getselectErrandsList();
    this.getQueryLineList();
  },
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/24 11:38:19
     * @Description 获取检测任务列表
     */
    getselectErrandsList(params) {
      if (!params) {
        params = { 
          pageNumber: this.pageNumber, 
          pageSize: this.pageSize 
        };
      }
      selectErrandWithPage(params).then(res => {
        if (res.rows) {
          res.rows.forEach((item, index) => {
            item.status = item.status == 1;
            this.$set(res.rows[index], "isRename", false);
          });
          this.tableData = res.rows;
          this.responseData = res.rows;
          this.total = res.total;
        }

        if (res.total === 0) {
          this.$alert("没有查询到缺陷分析任务", "提示", {
            confirmButtonText: "确定",
            callback: action => {}
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 10:08:42
     * @Description 查询所有线路给新增 / 筛选任务来选择
     */
    getQueryLineList() {
      getLineNames().then(res => {
        this.lineList = res.data;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:22:39
     * @Description 清除搜索栏
     */
    clearQuery() {
      this.getselectErrandsList();
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 11:50:21
     * @Description 点击添加任务按钮
     */
    addFn() {
      this.isCreateOrExport = true;
      this.missionTitle = "创建检测任务";
      this.errandFormDialogVisible = true;
      this.errandForm.errandName = "";
      this.errandForm.lineId = "";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 11:51:54
     * @Description 添加检测任务
     */
    createMission() {
      this.$refs.createErrandForm.validate(valid => {
        if (valid) {
          let params = {
            errandName: this.errandForm.errandName, //创建新任务-任务名称
            lineId: this.errandForm.lineId //创建新任务-线路名
          };
          getaddErrand(params).then(res => {
            if (res.code === "000000") {
              this.$message.success(res.data);
              this.errandFormDialogVisible = false;
              this.getselectErrandsList();
            } else {
              this.$message.error(res.data);
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 10:04:12
     * @Description 新增任务 / 导出报告关闭对话框时清除校验
     */
    clearValidate(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.isCreateOrExport = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/10 15:52:14
     * @Description 筛选任务
     */
    filterErrand(filter) {
      let params = { 
        pageNumber: this.pageNumber, 
        pageSize: this.pageSize 
      };

      console.log(filter == undefined);
      if(filter != undefined) {
        if(filter) {
          this.isFilter = true;
        }else {
          this.isFilter = false;
        }     
        params.pageNumber = this.pageNumber = 1;   
      }

      if (this.isFilter) {
        if (this.filterForm.lineId) {
          params.lineId = this.filterForm.lineId;
        }
        if (this.filterForm.errandName) {
          params.errandName = this.filterForm.errandName;
        }
      }

      this.filterErrandDialogVisible = false;
      this.getselectErrandsList(params);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:22:33
     * @Description 点击任务名或查看详情就跳转到任务详情界面
     */
    toDetails(row) {
      this.$router.push({ 
        path: "analysisDetails", 
        query: { 
          taskName: row.errandName, 
          errandId: row.errandId, 
          lineId: row.lineId, 
          lineName: row.lineName 
        } 
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:38:52
     * @Description 点击上传按钮
     */
    uploadPhoto(row) {
      this.uploadPhotoDialogVisible = true;
      this.isCreateOrExport = false;
      this.row = row;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:51:10
     * @Description 点击下载按钮
     */
    downReport(row) {
      this.errandFormDialogVisible = true;
      this.isCreateOrExport = false;
      this.missionTitle = "导出检测报告";
      this.errandForm.errandName = row.errandName;
      this.errandForm.lineId = row.lineId;
      this.errandForm.errandId = row.errandId;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:53:02
     * @Description 导出文件
     */
    exportReport() {
      showLoading("导出检测报告中...");
      getExportReport({ 
        errandId: this.errandForm.errandId, 
        exportMode: this.errandForm.exportMode
      })
        .then(res => {
          this.getOutExcel(`${this.errandForm.errandName}.pdf`, res);
          closeLoading();
        })
        .catch(err => {
          closeLoading();
          const _this = this;
          if (err.status == 500) {
            let data = err.data;
            const fileReader = new FileReader();
            fileReader.onload = function() {
              try {
                // 说明是普通对象数据，后台转换失败
                const jsonData = JSON.parse(fileReader.result); 
                _this.$message.error(jsonData.data);
              } catch (err) {

                // 解析成对象失败，说明是正常的文件流
                console.log(err);
              }
            };
            fileReader.readAsText(data);
          }
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:59:15
     * @Description fileName : 文件名 res:二进制流
     */
    getOutExcel(fileName, res) {
      let blob = new Blob([res], { type: "application/x-xls" });
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容 IE & EDGE
        navigator.msSaveBlob(blob, fileName);
        this.errandFormDialogVisible = false;
      } else {
        var link = document.createElement("a");
        // 兼容不同浏览器的URL对象
        const url = window.URL || window.webkitURL || window.moxURL;
        // 创建下载链接
        link.href = url.createObjectURL(blob);
        //命名下载名称
        link.download = fileName;
        //点击触发下载
        link.click();
        //下载完成进行释放
        url.revokeObjectURL(link.href);
        this.errandFormDialogVisible = false;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:27:30
     * @Description 点击修改
     */
    handleEdit(index, row) {
      this.tableData.forEach((item, index) => {
        item.isRename = false;
      });
      this.oldName = row.errandName;
      this.newName = row.errandName;
      
      // 展示input，允许修改 
      row.isRename = true;
      this.$nextTick(() => {
        this.$refs[row.id].focus();
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:28:14
     * @Description 失去焦点就更新任务名
     */
    rename(row) {
      let params = {
        id: row.id,
        errandName: this.newName
      };
      getupdateErrand(params).then(res => {
        if (res.code == "000000") {
          this.$message.success(res.data);
          row.isRename = false;
        }
        this.getselectErrandsList();
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/24 13:32:53
     * @Description 删除任务
     */
    handleDelete(row) {
      let params = {
        id: row.id
      };

      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        getdeleteErrand(params).then(res => {
          if (res.code === "000000") {
            this.$message.success(res.data);
            this.getselectErrandsList();
          } else {
            this.$message.error(res.data);
          }
        });
      }).catch(() => {
        this.$message("已取消删除");
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.analysis-page {
  height: calc(100% - 5vh);
  padding: 0 2vw;
  color: #fff;
  .main {
    height: calc(100% - 12vh);
    margin-top: 2vh;
    .top-button {
      height: 5vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-input {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
.pager-class {
  display: flex;
  justify-content: center;
}
</style>
